@use "@angular/material" as mat;
@import "preview-styles.css";

@include mat.core();

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
    ),
  )
);

html {
  @include mat.all-component-themes($light-theme);
}
